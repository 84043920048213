exports.database = {
  scholarships: [
    {
      id: '1',
      name: 'Jauw International Fellowship Program 2020',
      ongoing: true,
      country: 'JAP',
      department: '1',
      education_level: '3',
      scholarship_type: '1',
      rating: 4,
      duration: '4-6 เดือน',
      start_date: '2 สิงหาคม 2563',
      due_date: '31 มีนาคม 2563',
      amount: 100,
      qualifications: [
        'ผู้สมัครต้องเป็นผู้หญิงที่ไม่ใช่ชาวญี่ปุ่น และมีอายุต่ำกว่า 45 ปี',
        'ผู้สมัครต้องอาศัยอยู่นอกประเทศญี่ปุ่นขณะสมัคร',
        'ผู้สมัครต้องสำเร็จการศึกษาระดับปริญญาโทขึ้นไป',
        'ผู้สมัครต้องได้รับการติดต่อจากสถาบัน / มหาวิทยาลัยเพื่อทำการวิจัย และสามารถระบุรายละเอียด',
      ],
    },
    {
      id: '2',
      name: 'ทุนมหาวิทยาลัยซีหนาน เมืองฉงชิ่ง (Southwest University)',
      ongoing: true,
      country: 'CHINA',
      department: '2',
      education_level: '1',
      scholarship_type: '2',
      rating: 2,
      duration: '2-3 เดือน',
      start_date: '2 สิงหาคม 2563',
      due_date: '31 มีนาคม 2563',
      amount: 20,
      qualifications: [
        'ผลการเรียน 4.00 ขึ้นไป (4เทอม)',
        'ผลสอบ SHK4 180 ขึ้นไป',
      ],
    },
    {
      id: '3',
      name: 'ทุนรัฐบาลญี่ปุ่น JAPANESE GOVERNMENT (MEXT) ทุนญี่ปุ่นศึกษา',
      ongoing: true,
      country: 'JAP',
      department: '1',
      education_level: '2',
      scholarship_type: '3',
      rating: 3,
      duration: '5 เดือน',
      start_date: '2 สิงหาคม 2563',
      due_date: '31 มีนาคม 2563',
      amount: 5,
      qualifications: [
        'อายุไม่เกิน 25 ปี',
        'จบการศึกษาระดับมัธยมปลาย/ประกาศนียบัตรวิชาชีพ หรือกำลังศึกษาอยู่ชั้นปีสุดท้าย',
        'GPA ระดับปริญญาตรีไม่ต่ำกว่า 3.00',
        'กรณีที่มี GPA ไม่ต่ำกว่า 3.30 มีสิทธิ์สมัครสอบได้หากมีผลสอบวัดระดับ JLPT หรือ EJU',
      ],
    },
    {
      id: '4',
      name: 'The London School of Economics and Political Science (LSE)',
      ongoing: false,
      country: 'UK',
      department: '5',
      education_level: '1',
      scholarship_type: '4',
      rating: 3,
      duration: '5 เดือน',
      start_date: '2 สิงหาคม 2563',
      due_date: '31 มีนาคม 2563',
      amount: 5,
      qualifications: [
        'อายุไม่เกิน 25 ปี',
        'จบการศึกษาระดับมัธยมปลาย/ประกาศนียบัตรวิชาชีพ หรือกำลังศึกษาอยู่ชั้นปีสุดท้าย',
        'GPA ระดับปริญญาตรีไม่ต่ำกว่า 3.00',
        'กรณีที่มี GPA ไม่ต่ำกว่า 3.30 มีสิทธิ์สมัครสอบได้หากมีผลสอบวัดระดับ JLPT หรือ EJU',
      ],
    },
    {
      id: '5',
      name: 'Aston University',
      ongoing: true,
      country: 'UK',
      department: '4',
      education_level: '2',
      scholarship_type: '1',
      rating: 5,
      duration: '5 เดือน',
      start_date: '2 สิงหาคม 2563',
      due_date: '31 มีนาคม 2563',
      amount: 5,
      qualifications: [
        'อายุไม่เกิน 25 ปี',
        'จบการศึกษาระดับมัธยมปลาย/ประกาศนียบัตรวิชาชีพ หรือกำลังศึกษาอยู่ชั้นปีสุดท้าย',
        'GPA ระดับปริญญาตรีไม่ต่ำกว่า 3.00',
        'กรณีที่มี GPA ไม่ต่ำกว่า 3.30 มีสิทธิ์สมัครสอบได้หากมีผลสอบวัดระดับ JLPT หรือ EJU',
      ],
    },
    {
      id: '6',
      name: 'University of Bath',
      ongoing: false,
      country: 'UK',
      department: '3',
      education_level: '2',
      scholarship_type: '3',
      rating: 3,
      duration: '5 เดือน',
      start_date: '2 สิงหาคม 2563',
      due_date: '31 มีนาคม 2563',
      amount: 5,
      qualifications: [
        'อายุไม่เกิน 25 ปี',
        'จบการศึกษาระดับมัธยมปลาย/ประกาศนียบัตรวิชาชีพ หรือกำลังศึกษาอยู่ชั้นปีสุดท้าย',
        'GPA ระดับปริญญาตรีไม่ต่ำกว่า 3.00',
        'กรณีที่มี GPA ไม่ต่ำกว่า 3.30 มีสิทธิ์สมัครสอบได้หากมีผลสอบวัดระดับ JLPT หรือ EJU',
      ],
    },
    {
      id: '7',
      name: 'University of Surrey',
      ongoing: true,
      country: 'UK',
      department: '1',
      education_level: '1',
      scholarship_type: '2',
      rating: 4,
      duration: '5 เดือน',
      start_date: '2 สิงหาคม 2563',
      due_date: '31 มีนาคม 2563',
      amount: 5,
      qualifications: [
        'อายุไม่เกิน 25 ปี',
        'จบการศึกษาระดับมัธยมปลาย/ประกาศนียบัตรวิชาชีพ หรือกำลังศึกษาอยู่ชั้นปีสุดท้าย',
        'GPA ระดับปริญญาตรีไม่ต่ำกว่า 3.00',
        'กรณีที่มี GPA ไม่ต่ำกว่า 3.30 มีสิทธิ์สมัครสอบได้หากมีผลสอบวัดระดับ JLPT หรือ EJU',
      ],
    },
    {
      id: '8',
      name: 'Durham University',
      ongoing: true,
      country: 'UK',
      department: '2',
      education_level: '3',
      scholarship_type: '4',
      rating: 4,
      duration: '5 เดือน',
      start_date: '2 สิงหาคม 2563',
      due_date: '31 มีนาคม 2563',
      amount: 5,
      qualifications: [
        'อายุไม่เกิน 25 ปี',
        'จบการศึกษาระดับมัธยมปลาย/ประกาศนียบัตรวิชาชีพ หรือกำลังศึกษาอยู่ชั้นปีสุดท้าย',
        'GPA ระดับปริญญาตรีไม่ต่ำกว่า 3.00',
        'กรณีที่มี GPA ไม่ต่ำกว่า 3.30 มีสิทธิ์สมัครสอบได้หากมีผลสอบวัดระดับ JLPT หรือ EJU',
      ],
    },
    {
      id: '9',
      name: 'King’s college Lonon',
      ongoing: false,
      country: 'UK',
      department: '1',
      education_level: '4',
      scholarship_type: '3',
      rating: 4,
      duration: '5 เดือน',
      start_date: '2 สิงหาคม 2563',
      due_date: '31 มีนาคม 2563',
      amount: 5,
      qualifications: [
        'อายุไม่เกิน 25 ปี',
        'จบการศึกษาระดับมัธยมปลาย/ประกาศนียบัตรวิชาชีพ หรือกำลังศึกษาอยู่ชั้นปีสุดท้าย',
        'GPA ระดับปริญญาตรีไม่ต่ำกว่า 3.00',
        'กรณีที่มี GPA ไม่ต่ำกว่า 3.30 มีสิทธิ์สมัครสอบได้หากมีผลสอบวัดระดับ JLPT หรือ EJU',
      ],
    },
    {
      id: '10',
      name: 'University of Warwick',
      ongoing: false,
      country: 'UK',
      department: '1',
      education_level: '4',
      scholarship_type: '3',
      rating: 4,
      duration: '5 เดือน',
      start_date: '2 สิงหาคม 2563',
      due_date: '31 มีนาคม 2563',
      amount: 5,
      qualifications: [
        'อายุไม่เกิน 25 ปี',
        'จบการศึกษาระดับมัธยมปลาย/ประกาศนียบัตรวิชาชีพ หรือกำลังศึกษาอยู่ชั้นปีสุดท้าย',
        'GPA ระดับปริญญาตรีไม่ต่ำกว่า 3.00',
        'กรณีที่มี GPA ไม่ต่ำกว่า 3.30 มีสิทธิ์สมัครสอบได้หากมีผลสอบวัดระดับ JLPT หรือ EJU',
      ],
    },
    {
      id: '11',
      name: 'University of Oxford',
      ongoing: false,
      country: 'UK',
      department: '1',
      education_level: '4',
      scholarship_type: '1',
      rating: 4,
      duration: '5 เดือน',
      start_date: '2 สิงหาคม 2563',
      due_date: '31 มีนาคม 2563',
      amount: 5,
      qualifications: [
        'อายุไม่เกิน 25 ปี',
        'จบการศึกษาระดับมัธยมปลาย/ประกาศนียบัตรวิชาชีพ หรือกำลังศึกษาอยู่ชั้นปีสุดท้าย',
        'GPA ระดับปริญญาตรีไม่ต่ำกว่า 3.00',
        'กรณีที่มี GPA ไม่ต่ำกว่า 3.30 มีสิทธิ์สมัครสอบได้หากมีผลสอบวัดระดับ JLPT หรือ EJU',
      ],
    },
    {
      id: '12',
      name: 'University of College London',
      ongoing: true,
      country: 'UK',
      department: '1',
      education_level: '2',
      scholarship_type: '2',
      rating: 3,
      duration: '5 เดือน',
      start_date: '2 สิงหาคม 2563',
      due_date: '31 มีนาคม 2563',
      amount: 5,
      qualifications: [
        'อายุไม่เกิน 25 ปี',
        'จบการศึกษาระดับมัธยมปลาย/ประกาศนียบัตรวิชาชีพ หรือกำลังศึกษาอยู่ชั้นปีสุดท้าย',
        'GPA ระดับปริญญาตรีไม่ต่ำกว่า 3.00',
        'กรณีที่มี GPA ไม่ต่ำกว่า 3.30 มีสิทธิ์สมัครสอบได้หากมีผลสอบวัดระดับ JLPT หรือ EJU',
      ],
    },
    {
      id: '13',
      name: 'University of Nottingham',
      ongoing: false,
      country: 'UK',
      department: '1',
      education_level: '1',
      scholarship_type: '1',
      rating: 4,
      duration: '5 เดือน',
      start_date: '2 สิงหาคม 2563',
      due_date: '31 มีนาคม 2563',
      amount: 5,
      qualifications: [
        'อายุไม่เกิน 25 ปี',
        'จบการศึกษาระดับมัธยมปลาย/ประกาศนียบัตรวิชาชีพ หรือกำลังศึกษาอยู่ชั้นปีสุดท้าย',
        'GPA ระดับปริญญาตรีไม่ต่ำกว่า 3.00',
        'กรณีที่มี GPA ไม่ต่ำกว่า 3.30 มีสิทธิ์สมัครสอบได้หากมีผลสอบวัดระดับ JLPT หรือ EJU',
      ],
    },
  ],
  countries: [
    { id: 'TH', name: 'ประเทศไทย' },
    { id: 'UK', name: 'สหรัฐราชอาณาจักร' },
    { id: 'US', name: 'สหรัฐอเมริกา' },
    { id: 'JAP', name: 'ประเทศญี่ปุ่น' },
    { id: 'CHINA', name: 'สาธารณรัฐประชาชนจีน' },
  ],
  departments: [
    { id: '1', name: 'ไม่จำกัด' },
    { id: '2', name: 'วิศวกรรมศาสตร์' },
    { id: '3', name: 'การบริหารและจัดการธุรกิจ' },
    { id: '4', name: 'สถาปัตยกรรมและการออกแบบ' },
    { id: '5', name: 'ภาษาศาสตร์' },
    { id: '6', name: 'วิทยาศาสตร์ข้อมูล' },
    { id: '7', name: 'ศิลปกรรมและมนุษยศาสตร์' },
    { id: '8', name: 'จิตวิทยา' },
    { id: '9', name: 'กฎหมาย' },
    { id: '10', name: 'สังคมศาสตร์และนิเทศศาสตร์' },
    { id: '11', name: 'การแพทย์' },
    { id: '12', name: 'ครุศาสตร์และการศึกษา' },
    { id: '13', name: 'สิ่งแวดล้อม' },
    { id: '14', name: 'การเงิน' },
    { id: '15', name: 'เศรษฐศาสตร์' },
    { id: '16', name: 'วิทยาศาสตร์' },
  ],
  education_levels: [
    { id: '1', name: 'ปริญญาตรี' },
    { id: '2', name: 'ปริญญาโท' },
    { id: '3', name: 'ปริญญาเอก' },
  ],
  scholarship_types: [
    { id: '1', name: 'ทุนเต็มจำนวน' },
    { id: '2', name: 'ทุนไม่เต็มจำนวน' },
    { id: '3', name: 'ทุนวิจัย' },
    { id: '4', name: 'ทุนให้เปล่า' },
    { id: '5', name: 'ทุนมีข้อผูกมัด' },
  ],
}
